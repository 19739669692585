<template>
  <div class="wrapper">
    <v-menu
      offset-y
      min-width="max-content"
      left
      :attach="typeof attach !== 'undefined' ? attach : mountedNode"
      :content-class="contentClass"
      :top="isOutOfView"
      :value="isOpen"
      :z-index="zIndex"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          @click="checkActionsVisible"
          v-on="on"
        >
          <icon-tooltip
            :icon-name="'i.DotsVertical'"
            :tooltip-text="$t('t.Actions')"
          />
        </v-btn>
      </template>
      <resize-observer @resize="recomputeHeight">
        <v-card
          ref="menu-content"
          class="ma-0 text-left"
        >
          <v-list>
            <v-list-item-group>
              <template v-if="actionsVisibleComputed.length > 0">
                <v-list-item
                  v-for="(action, i) in actionsVisibleComputed"
                  :key="i"
                  link
                  @click.stop="doAction(action)"
                >
                  <v-icon class="mr-4">{{action.icon}}</v-icon>
                  <v-list-item-content>
                    <span>{{action.text}}</span>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-item>
                  <v-list-item-content>
                    <span> {{$t('t.NoActionsAvailable')}}</span>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </resize-observer>
    </v-menu>
  </div>
</template>

<script>
export default {
  components: {
    IconTooltip: () => import('@/components/icon-tooltip'),
    ResizeObserver: () => import('@/components/resize-observer')
  },
  data () {
    return {
      mountedNode: undefined,
      isOutOfView: false,
      actionsVisible: [],
      isOpen: false
    }
  },
  mounted () {
    this.mountedNode = this.$el
  },
  activated () {
    this.mountedNode = this.$el
  },
  methods: {
    recomputeHeight () {
      if (!this.$refs?.['menu-content']?.$el) { return }
      const content = this.$refs['menu-content'].$el.closest('content .documents-by-type,aside .v-navigation-drawer__content')
      if (!content) { return }
      this.isOutOfView = content.getBoundingClientRect().bottom < (this.$refs['menu-content'].$el.getBoundingClientRect().bottom + 58)
    },
    async checkActionsVisible (event) {
      this.$emit('click', event)

      const tempVec = []
      this.actions.forEach(async action => {
        const isVisible = await action.isVisible(this.item)
        if (isVisible) {
          tempVec.push(action)
        }
      })
      this.actionsVisibleComputed = tempVec
      this.isOpen = true
    },
    doAction (action) {
      action.action(this.item)
      this.isOpen = false
    }
  },
  computed: {
    actionsVisibleComputed: {
      get () {
        return this.actionsVisible
      },
      set (v) {
        this.actionsVisible = v
      }
    }
  },
  props: {
    actions: Array,
    attach: {
      type: [String, Boolean, Object, HTMLElement],
      default: undefined
    },
    contentClass: String,
    item: [Object, Number],
    zIndex: {
      default: 2,
      type: [Number, String]
    }
  }
}
</script>

<style lang="stylus" scoped>
.wrapper
  position relative
</style>
